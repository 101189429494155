import React, { useState } from 'react';
import styled from 'styled-components';
import Headline from '../atoms/Headline';
import Text from '../atoms/Text';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { Link } from 'gatsby';

const Container = styled.div``;

const FormContainer = styled.div`
  form {
    display: flex;
    flex-direction: column;

    @media (min-width: ${({ theme }) => theme.tablet}) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      /* flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between; */
    }

    .input-label {
      position: relative;
      width: 100%;
      font-size: ${({ theme }) => theme.size14};
      font-weight: ${({ theme }) => theme.medium};

      &:not(:last-child) {
        margin-bottom: 20px;
      }

      .required-info {
        position: absolute;
        left: 0;
        bottom: 0;
        transform: translateY(100%);
        color: red;
        font-family: ${({ theme }) => theme.fontFamily};
        font-size: ${({ theme }) => theme.size14};
      }

      @media (min-width: ${({ theme }) => theme.tablet}) {
        width: 90%;
      }
    }

    .additional-options {
      display: flex;
      align-items: center;

      label {
        margin-left: 15px;
      }
      input {
        width: 15px !important;
        margin: 0 0 0 5px !important;
      }
    }
    .bottom-inputs {
      display: flex;
      flex-direction: column;
      @media (min-width: ${({ theme }) => theme.desktop}) {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        .bottom-label {
          width: 45%;
          &:not(:last-child) {
            margin-bottom: 30px;
          }
        }
      }
    }

    .checkboxes {
      display: flex;
      flex-direction: column;

      .checkbox-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        label {
          font-weight: ${({ theme }) => theme.regular};
        }

        input {
          width: 15px !important;
          height: 15px !important;
          margin: 0 0 0 10px !important;
        }
      }
    }
  }

  form input:not(#agreement),
  form select {
    margin-top: 6px;
    background-color: #f8f8f8;
    border: none;
    border-bottom: 2px solid #dfdfdf;
    outline: none;
    height: 40px;
    width: 100%;
    padding: 10px;
    font-family: ${({ theme }) => theme.fontFamily};
    font-size: ${({ theme }) => theme.size14};
  }

  .submit-btn {
    margin-top: 50px;
    width: 180px;
    height: 50px;
    border: none;
    cursor: pointer;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
    background-color: ${({ theme }) => theme.yellow};
    color: ${({ theme }) => theme.white};
    font-size: ${({ theme }) => theme.size18};
    font-family: ${({ theme }) => theme.fontFamily};
    font-weight: ${({ theme }) => theme.regular};
    align-self: center;
    &:hover {
      background-color: #232a3e;
    }
  }
`;

const SubmitSuccessfull = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  bottom: 0;
  left: 50%;
  width: 90%;
  max-width: 650px;
  padding: 15px 30px;
  text-align: center;
  height: 200px;
  background-color: ${({ theme }) => theme.primary};
  border-radius: 20px;
  z-index: 999;
  transform: translate(-50%, -10%);
`;

const SubmitText = styled(Text)`
  text-align: center;
  color: ${({ theme }) => theme.white};
`;

const SubmitButton = styled.button`
  margin-top: 30px;
  width: 180px;
  height: 50px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.white};
  cursor: pointer;
  background-color: transparent;
  color: ${({ theme }) => theme.white};
  font-size: ${({ theme }) => theme.size18};
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: ${({ theme }) => theme.regular};
  transition: all 0.3s ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.black};
    background-color: ${({ theme }) => theme.white};
  }
`;

const Agreement = styled.div`
  position: relative;
  display: flex;
  input {
    position: absolute;
    top: 5px;
    left: 0;
    width: 15px !important;
    height: 15px !important;
  }

  label {
    padding-left: 25px;
    font-size: 12px;
  }

  .required-info {
    position: absolute;
    left: 0;
    bottom: 0;
    transform: translateY(100%);
    color: red;
    font-family: ${({ theme }) => theme.fontFamily};
    font-size: ${({ theme }) => theme.size16};
  }

  a {
    margin-left: 2px;
  }
`;

const ReceptionFormRight = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formError, setFormError] = useState(false);
  const [pickedQuestion, setPickedQuestion] = useState(1);

  const handleCloseButton = () => {
    setFormSubmitted(false);
    setFormError(false);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleSelectOption = (e) => {
    const index = e.target.selectedIndex;
    const optionElement = e.target.childNodes[index];
    const optionElementId = optionElement.getAttribute('id');
    setPickedQuestion(parseInt(optionElementId, 10));
  };

  // https://formsubmit.co/ajax/e810423d7c832016206418a518560311

  const onSubmit = (data, cartItems) => {
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios
      .post('https://formsubmit.co/ajax/e810423d7c832016206418a518560311', {
        RodzajNieruchomości: data.RodzajNieruchomości,
        Adres: data.Adres,
        Powierzchnia: data.Powierzchnia,
        LiczbaPokoi: data.LiczbaPokoi,
        MiejscePostojowe: data.MiejscePostojowe,
        KomorkaLokatorska: data.KomorkaLokatorska,
        DodatkoweInformacje: data.DodatkoweInformacje,
        Imie: data.Imie,
        Telefon: data.Telefon,
        Email: data.Email,
        DataOdbioru: data.DataOdbioru,
      })
      .then((response) => {
        if (response.status === 200) {
          setFormSubmitted(true);
          setFormError(false);
        } else {
          setFormSubmitted(false);
          setFormError(true);
        }
      })
      .catch((error) => {
        setFormSubmitted(false);
        setFormError(true);
      });
  };
  return (
    <Container>
      {formSubmitted && (
        <SubmitSuccessfull>
          <SubmitText>
            Dziękujemy za skorzystanie z formularza ! <br />
            Skontaktujemy się z Państwem tak szybko jak to tylko możliwe !
          </SubmitText>
          <SubmitButton onClick={handleCloseButton}>Zamknij</SubmitButton>
        </SubmitSuccessfull>
      )}

      {formError && (
        <SubmitSuccessfull>
          <SubmitText>
            Niestety wystąpił błąd! Prosimy o wysłanie wiadomości bezpośrednio
            na nasz adres email.
          </SubmitText>
          <SubmitButton onClick={handleCloseButton}>Zamknij</SubmitButton>
        </SubmitSuccessfull>
      )}
      <FormContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
          <label className='input-label'>
            Rodzaj nieruchomości
            <select {...register('RodzajNieruchomości', { required: true })}>
              <option value='Mieszkanie'>Mieszkanie</option>
              <option value='Dom'>Dom</option>
              <option value='Lokal'>Lokal</option>
              <option value='Inne'>Inne</option>
            </select>
            {errors.RodzajNieruchomości && (
              <span className='required-info'>To pole jest wymagane</span>
            )}
          </label>
          <label className='input-label'>
            Adres
            <input
              placeholder='Ulica, kod pocztowy, miejscowość'
              {...register('Adres', { required: true })}
            />
            {errors.Adres && (
              <span className='required-info'>To pole jest wymagane</span>
            )}
          </label>
          <label className='input-label'>
            Powierzchnia
            <select {...register('Powierzchnia', { required: true })}>
              <option value='20-30m'>
                20 - 30m<sup>2</sup>
              </option>
              <option value='30-40m'>
                30 - 40m<sup>2</sup>
              </option>
              <option value='40-50m'>
                40 - 50m<sup>2</sup>
              </option>
              <option value='50-60m'>
                50 - 60m<sup>2</sup>
              </option>
              <option value='60-80m'>
                60 - 80m<sup>2</sup>
              </option>
              <option value='pow. 80'>
                powyżej 80m<sup>2</sup>
              </option>
              <option value='pow. 100'>
                powyżej 100m<sup>2</sup>
              </option>
              <option value='inna'>Inna</option>
            </select>
            {errors.Powierzchnia && (
              <span className='required-info'>To pole jest wymagane</span>
            )}
          </label>
          <label className='input-label'>
            Liczba pokoi
            <select {...register('LiczbaPokoi', { required: true })}>
              <option value='kawalerka'>Kawalerka</option>
              <option value='2'>2</option>
              <option value='3'>3</option>
              <option value='4 i więcej'>4 i więcej</option>
            </select>
            {errors.LiczbaPokoi && (
              <span className='required-info'>To pole jest wymagane</span>
            )}
          </label>

          {/* <label
            className='input-label additional-options'
          >
            Czy do mieszkania/lokalu przynależy miejsce postojowe?
            <label for='q1'>Tak</label>
            <input
              type='checkbox'
              value='Tak'
              name='q1'
              {...register('MiejscePostojowe', { required: false })}
            ></input>
          </label>

          <label
            className='input-label additional-options'
          >
            Czy do mieszkania/lokalu przynależy komórka lokatorska?
            <label for='q2'>Tak</label>
            <input
              {...register('KomorkaLokatorska', { required: false })}
              type='checkbox'
              value='Tak'
              name='q2'
            ></input>
          </label>

          <label
            className='input-label'
          >
            Czy były przeprowadzone zmiany lokatorskie? Jeśli tak to jakie?
            <input
              type='text'
              placeholder='Zmiany lokatorskie'
              {...register('ZmianyLokatorskie', { required: false })}
            ></input>
          </label> */}

          <label className='input-label checkboxes'>
            Czy mieszkanie zawiera:
            <div className='checkbox-wrapper'>
              <label for='q1'>Komórkę lokatorską</label>
              <input
                {...register('KomorkaLokatorska', { required: false })}
                type='checkbox'
                value='Tak'
                name='q1'
              />
            </div>
            <div className='checkbox-wrapper'>
              <label for='q2'>Miejsce postojowe</label>
              <input
                {...register('MiejscePostojowe', { required: false })}
                type='checkbox'
                value='Tak'
                name='q2'
              />
            </div>
          </label>

          <label className='input-label'>
            Dodatkowe informacje
            <input
              type='text'
              {...register('Dodatkowe informacje', { required: false })}
            />
          </label>

          <label className='input-label bottom-label'>
            Imię
            <input
              placeholder='Imię'
              {...register('Imie', { required: true })}
            />
            {errors.Imie && (
              <span className='required-info'>To pole jest wymagane</span>
            )}
          </label>
          <label className='input-label bottom-label'>
            Telefon
            <input
              placeholder='Telefon'
              {...register('Telefon', { required: true })}
            />
            {errors.Telefon && (
              <span className='required-info'>To pole jest wymagane</span>
            )}
          </label>
          <label className='input-label bottom-label'>
            Adres e-mail
            <input
              placeholder='Adres e-mail'
              {...register('Email', { required: true })}
            />
            {errors.Email && (
              <span className='required-info'>To pole jest wymagane</span>
            )}
          </label>
          <label className='input-label bottom-label'>
            Data odbioru
            <input
              type='datetime-local'
              {...register('DataOdbioru', { required: true })}
            />
            {errors.DataOdbioru && (
              <span className='required-info'>To pole jest wymagane</span>
            )}
          </label>
          <Agreement>
            <label for='agreement'>
              Wypełnienie formularza oznacza, że podane w nim dane osobowe będą
              przetwarzane w celu przesłania oferty oraz kontaktu w jej sprawie.
              Zasady przetwarzania i informacje o administratorze znajdziesz
              <Link to='/polityka-prywatnosci-i-cookies'>tutaj.</Link>
              <input
                type='checkbox'
                id='agreement'
                name='agreement'
                {...register('Agreement', { required: true })}
              />
            </label>
            {errors.Agreement && (
              <span className='required-info'>To pole jest wymagane</span>
            )}
          </Agreement>

          <button type='submit' className='submit-btn'>
            Wyślij
          </button>
        </form>
      </FormContainer>
    </Container>
  );
};

export default ReceptionFormRight;
