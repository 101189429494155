import React from 'react';
import MainTemplate from '../../templates/MainTemplate';
import ReceptionForm from '../../components/organisms/ReceptionForm';

const ApartmentRecieve = () => {
  return (
    <MainTemplate
      isMainPage={false}
      title='Odbiory mieszkań od dewelopera'
      metaDescription='Zarezerwuj odbiór mieszkania'
      isDark
    >
      <ReceptionForm />
    </MainTemplate>
  );
};

export default ApartmentRecieve;
