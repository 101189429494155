import React from 'react';
import styled from 'styled-components';
import ReceptionFormRight from '../atoms/ReceptionFormRight';
import BgImage from '../../assets/images/reception-img.jpg';
import Headline from '../atoms/Headline';
import { Link } from 'gatsby';

const Wrapper = styled.div`
  @media (min-width: ${({ theme }) => theme.smLaptop}) {
    display: flex;
  }
`;

const ColumnLeft = styled.div`
  width: 100%;
  height: 300px;
  background-image: url(${BgImage});
  background-size: cover;
  background-position: center;
  position: relative;
  padding: 80px 15px;
  display: flex;
  align-items: center;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 0;
  }

  @media (min-width: ${({ theme }) => theme.tablet}) {
    padding: 80px 5%;
  }

  @media (min-width: ${({ theme }) => theme.smLaptop}) {
    width: 50%;
    height: unset;
    padding: 200px 50px 100px 5%;
    align-items: flex-start;
  }
`;

const BackToHome = styled(Link)`
  font-weight: ${({ theme }) => theme.regular};
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  position: absolute;
  left: 15px;
  bottom: 15px;

  svg {
    margin-right: 8px;
  }

  @media (min-width: ${({ theme }) => theme.tablet}) {
    left: 5vw;
  }

  @media (min-width: ${({ theme }) => theme.laptop}) {
    top: 100px;
    bottom: unset;
  }
`;

const ColumnRight = styled.div`
  width: 100%;
  padding: 50px 15px;

  @media (min-width: ${({ theme }) => theme.tablet}) {
    padding: 50px 5%;
  }

  @media (min-width: ${({ theme }) => theme.smLaptop}) {
    width: 50%;
    padding: 100px 5% 100px 25px;
  }

  @media (min-width: ${({ theme }) => theme.desktop}) {
    width: 50%;
    padding: 100px 5% 100px 50px;
  }
`;

const StyledHeadline = styled(Headline)`
  color: #fff;
  position: relative;
  z-index: 10;
`;

const ReceptionForm = () => {
  return (
    <Wrapper>
      <ColumnLeft>
        <BackToHome to='/' aria-label='Powrót na stronę główną'>
          <svg
            width='38'
            height='12'
            viewBox='0 0 38 12'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M0.845703 5.9668L10.8457 11.7403V0.193294L0.845703 5.9668ZM38 4.9668L9.8457 4.9668V6.9668L38 6.9668V4.9668Z'
              fill='white'
            />
          </svg>
          Powrót
        </BackToHome>
        <StyledHeadline>
          Zarezerwuj termin <br /> odbiór techniczny lokalu
        </StyledHeadline>
      </ColumnLeft>
      <ColumnRight>
        <ReceptionFormRight />
      </ColumnRight>
    </Wrapper>
  );
};

export default ReceptionForm;
